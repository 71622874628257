import React, { Fragment } from 'react'

const WorkFlowDetails = ({name, date, stage, from, mainHeading, changeLabelOrder = false, task_assignee, initiated_date, due_date}) => {
    const defaultLabelsOrder = () => {
        return (
            <div>
                <div className='workflow_details'>
                    <h3>{('Workflow Name')}: <span>{name}</span></h3> 
                    <h3>{('Stage')}: <span>{stage}</span></h3>
                    <h3>{('Initiate Date')}: <span>{date && date.split(", ").shift()}</span></h3>
                    <h3>{('From')}: <span>{from}</span></h3> 
                </div>
                <div className='work_extra_details'>
                    <h2>{mainHeading}</h2>
                </div>
            </div>
        )
    }

    const changedLabelsOrder = () => {
        return (
            <div>
                <div className='work_extra_details_chng'>
                    <h2>{mainHeading}</h2>
                </div>
                <div className='workflow_details'>
                    <h3>{('Workflow Name')}: <span>{name}</span></h3> 
                    <h3>{('Task Assignee')}: <span>{task_assignee}</span></h3>
                    <h3>{('Stage')}: <span>{stage}</span></h3>
                    <h3>{('Initiated Date')}: <span>{initiated_date}</span></h3>
                    <h3>{('Due Date')}: <span>{due_date} </span></h3> 
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {changeLabelOrder ? changedLabelsOrder() : defaultLabelsOrder()} 
        </Fragment>
    )
}

export default (WorkFlowDetails);