import Cookies from "js-cookie";
const query = new URLSearchParams(window.location.search);
const subdomainParams = query.get('subdomain');
const subdomainCookies = Cookies.get('lynkaz_workflow');

let Settings = {
    baseUrl: 'https://fn-prod-lynkaz-workflow.azurewebsites.net/api/',
    apiWorkflowUrl: 'https://fn-prod-lynkaz-workflow.azurewebsites.net/api',
    apiBaseUrl: `https://prod.system.lynkaz.com`, // assets
    loginUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.common.lynkaz.com`,
    apiUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.common.lynkaz.com`,
    apiOrgUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.org.lynkaz.com`,
    apiVendorUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.vendor.lynkaz.com`,
    apiItemUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.item.lynkaz.com`,
    apiPurchaseOrderUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.po.lynkaz.com`,
    apiProcurementSettingsUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.config.lynkaz.com`,
    apiPurchaseRequisitionUrl: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.pr.lynkaz.com`,
    apiUrls: {
        segments: `https://${subdomainParams ? subdomainParams : subdomainCookies}.prod.financials.lynkaz.com`
    }, 
    defaultLang: 'en',
    userTokenKey: 'lynkaq_user_token',
    subdomainKey: 'lynkaz_workflow',
    dateFormateKey: 'lynkaq_dateformate',
    languageKey: 'app_lang',
    userTokenExpireDate: 1,
    redirectUrlAfterLogin: 'template',
    loginLink: 'login',
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    debug: true,
    defaultDateFormate: 'mm/dd/yyyy',
    dropdownPageSize:10,
    source_id: {
        tenant: 1, warehouse: 4, site: 3, entity: 2, address: 5, vendor: 6, vendor_registration: 7, bank: 8
    },
    enum_workflow_data: {
        instance_state: [
            {
                label: 'All',
                value: 'all'
            },
            {
                label: 'Completed',
                value: 'completed'
            },
            {
                label: 'In Completed',
                value: 'incompleted'
            } 
        ],
        task_types_recall: {
           id: 6,
           name: 'Recall'
        },
        task_types_reassign: {
           id: 12,
           name: 'Task Re-assign'
        },
    },
    validator: {
        max: {
            code: 10,
            name: 80
        }
    }
}
export default Settings;
