import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helper from '../../inc/Helper';
import $ from 'jquery'; 
import { checkIdsInObject } from '../../inc/Validation';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: []
        }
    }
   
    menuItemClickHandler(e) {
        $('.sidebar_menu li').removeClass('active');
        $(e.target).parents('li').addClass('active')
    }
    render() {  
        let sidebarStyle = {};
        if (this.props.appOptions.options.isSidebarOpen) {
            sidebarStyle.width = '250px';
        } else {
            sidebarStyle.width = '0';
            sidebarStyle.overflow = "hidden";
            sidebarStyle.visibility = "hidden";
        }
        return (
            <div className='sidebar_section' style={sidebarStyle}>
                <div className='sidebar_section_inner'>
                    <Link className='main_logo rs_sticky_tag' onClick={e => this.menuItemClickHandler(e)} to={this.props?.auth?.user?.admin_user?'/template':'/workflow'}><img src="/images/logo_white.svg" alt="Logo" /> </Link>
                    <ul className='sidebar_menu'>
                        { this.props?.auth?.user?.admin_user ? <li><Link to="/template" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/reports.svg" alt="templates" /><span>{Helper.getLabel(this.props.language, '20362', 'Templates')}</span></Link></li> : ""}
                        { !this.props?.auth?.user?.admin_user ? <li><Link to="/workflow" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/hierarchy-icon-1.svg" alt="workflow" /><span>{Helper.getLabel(this.props.language, '20362', 'Workflow')}</span></Link></li> : ""}
                        { !this.props?.auth?.user?.admin_user ? <li><Link to="/stages" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/users-gray.svg" alt="stages" /><span>{Helper.getLabel(this.props.language, '20362', 'Stages')}</span></Link></li> : ""}
                        { !this.props?.auth?.user?.admin_user ? <li><Link to="/instances" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/sidebar/request.svg" alt="instances" /><span>{Helper.getLabel(this.props.language, '20362', 'Failed Instances')}</span></Link></li> : ""}
                        { checkIdsInObject(this.props?.auth?.user?.roles, ["79", "87"]) ? <li><Link to="/workflow-pending-tasks" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/sidebar/command.svg" alt="workflow pending tasks" /><span>{Helper.getLabel(this.props.language, '20362', 'Workflow Pending Tasks')}</span></Link></li> : ""}
                    </ul>
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        appOptions: state.options,
        language: state.language
    }
}
 
export default connect(mapStateToProps) (Sidebar)
