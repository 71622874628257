import axios from "axios";  
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";

export default class PendingTaskCommonApiCalls {
    api;

    constructor() {
        this.api = Api;
        this.api.setUserToken();
    }

    async getWorkFlowRequestTypes() {
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=assignee_type`);
        if (res.status === 200) {
            return res.data?.data?.filter(val => ["Team", "Position", "User"].includes(val.name));
        }
        return res;
    }

    async getWorkFlowDurationTypes() { 
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=duration_type`);
        if (res.status === 200) return res.data.data;
        return res;
    }
    
    async getAllSystemSources(){
        const res = await this.api.axios().get(Settings.apiUrl + `/source`);
        if (res.data.status === "success") return res.data.data;
        return res;
    }
    
    async getWorkFlowCompletionPolicy() { 
        const res = await axios.get(Settings.apiWorkflowUrl + `/enum/get?enum_type=completion_policy`);
        if (res.status === 200) return res.data.data;
        return res;
    }
 
    async getWorkFlowPositions() {
        try {
            const res = await this.api.axios().get(Settings.apiOrgUrl + `/workflow/get_positions`);
            if (res.status === 200 && res.data.status === "success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }
    
    async getWorkFlowTeams(page_number, page_size) {
        try {
            const res = await this.api.axios().get(Settings.apiUrl + `/workflow/get_teams/${page_number}/${page_size}`);
            if (res.status === 200 && res.data.status === "success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }
    
    async getWorkFlowUsers(page_number, page_size) {
        try {
            const res = await this.api.axios().post(Settings.apiUrl + `/workflow/get_users/${page_number}/${page_size}`, {"name":null});
            if (res.status === 200 || res.data.status === "Success") {
                return res.data.data;
            } else { return [] }
        } catch (error) { return [] }
    }

    async getWorkflowPendingTasks (user_id, task_type, page_number) {
        const res = await this.api.axios().post(Settings.apiUrl + `/workflow/pending_actions?page_number=${page_number}&page_size=10`, 
            {
                "task_type": task_type,
                "for_workflow": true,
                "user_id": user_id ? user_id : null
             }
        );
        if (res.data.status === "success") return res?.data;
        return {
            data: [],
            total_records: 0
        };
    };

 
    async getWorkFlowCurrentAssignee(tenant_id, module, module_item_id) { 
        const resAssignee = await axios.get(Settings.apiWorkflowUrl + `/instance/current-assignee?tenant_id=${tenant_id}&module_id=${module}&module_item_id=${module_item_id}`);

        if (resAssignee.status === 200){
            const Updated_data = []; 
      
            for (const element of resAssignee?.data?.data) {
                const res = await this.api.axios().get(Settings.apiUrl + `/get_user/${element.task_assignee}`);
                const userData = res?.data;
 
                if (userData.status === "success") {    
                    Updated_data.push({
                        workflow_name: element?.workflow_name ? element?.workflow_name : null,
                        stage: element?.stage ? element?.stage : null,
                        employee: userData.data.employee_name ? userData.data.employee_name : null,
                        initiated_date: element.initiated_date ? element.initiated_date : null,
                        due_date: element.due_date ? element.due_date : null 
                    });
                } else {
                    Updated_data.push({
                        workflow_name: element?.workflow_name ? element?.workflow_name : null,
                        employee: null,
                        stage: element?.stage ? element?.stage : null,
                        initiated_date: element.initiated_date ? element.initiated_date : null,
                        due_date: element.due_date ? element.due_date : null 
                    });
                }
            }

            return Updated_data
        }
    }

};