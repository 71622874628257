import React, { useState, useEffect, Fragment, useRef } from 'react'
import WorkFlowDetails from './WorkFlowDetails'; 
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import PaginatedDropdown from '../../../components/Forms/PaginatedDropdown'; 
import Dropdown from "../../../components/Forms/Dropdown";
import Input from '../../../components/Forms/Input';
import Helper from '../../../inc/Helper'; 
import axios from 'axios';
import $ from 'jquery';
import Settings from '../../../inc/Settings'; 
import PendingTaskCommonApiCalls from './PendingTaskCommonApiCalls'; 
import { connect } from 'react-redux';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';  
import NewOverlayLoader from '../../../components/PurchaseRequisition/common/NewOverlayLoader';
import Api from '../../../inc/Api';
import Button from '../../../components/inc/Button';

/*

Workflow System - LYNKAZ-

actions triggering view,
actions List / types listings:
    "Approve" 
    "Reject"
    "Request more information"
    "Delegate"
    "Recall"
    "View current assignment" 
    "Task Re-assign" 
    "Action History" 
    "Submit"
    "Sendback"
    "Answer"
*/

let cancelTokenSource = axios.CancelToken.source(); 
let totalDropdownPageUser = 1;
let totalDropdownPageTeam = 1;

const WorkflowOffCanvas = ({
    closeSidebar, 
    isOpen, 
    type,
    workFlowActionObject, 
    reloadFunction,
    source_id, 
    sources,
    auth,
    executeApiOfTaskAction,
    setEnableFields,
    workFlowFunction
}) => {

    const [workflowActionDetails, setWorkflowActionDetails] = useState({
        description: null,
        duration_type: null,
        user_id: null,
        duration: null,
        teams: null,
        positions: null,
        role_id: null,
        new_assignee_type: null,
        completion_policy: null, 
        percentage_of_approver: null
    })
    const [isTransitioning, setIsTransitioning] = useState(false); 
    const [isApiCallMade, setIsApiCallMade] = useState(false);

    // Dropdown States & Refs
    const [positions, setPositions] = useState([]); 
    const [teams, setTeams] = useState([]); 
    const [users, setUsers] = useState([]); 
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [openKPIViolateIndex, setOpenKPIViolateIndex] = useState(null);  
    const [request, setRequests] = useState([]);
    const [duration, setDuration] = useState([]);
    const [policy, setPolicy] = useState([]); 

  
    // Toggle Dropdowns Based On Request Types
    const [showPolicy, setShowPolicy] = useState(false) 
    const [showPositions, setShowPositions] = useState(false)
    const [showTeams, setShowTeams] = useState(false)
    const [showUsers, setShowUsers] = useState(false)
    const [showPercentageApprover, setShowPercentageApprover] = useState(false) 
 
    // Closing Modal Popups & Div Refs
    const KPIViolateModalPopup = useRef(null)
    const DropdownDateModalPopup = useRef(null) 
 
    // API Calls -
    useEffect(() => {   
        $('html').addClass('rs_popup_open'); // triggering the class to remove the scrollbar,
 
        fetchDataFromAPIForDropdowns(); 
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (KPIViolateModalPopup.current && !KPIViolateModalPopup.current.contains(event.target)) OpenKPIViolate(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openKPIViolateIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (DropdownDateModalPopup.current && !DropdownDateModalPopup.current.contains(event.target)) OpenDropdown(-1); 
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => { document.removeEventListener('mousedown', handleClickOutside) }
    }, [openDropdownIndex])

    const fetchDataFromAPIForDropdowns = async () => {
        const apiCalls = new PendingTaskCommonApiCalls();
    
        try {
            if (["Task Re-assign"].includes(type.label)) {
                const [positions, teams, users, requestTypes, durationType, completionPolicy] = await Promise.all([
                    apiCalls.getWorkFlowPositions(),
                    apiCalls.getWorkFlowTeams(1, 10),
                    apiCalls.getWorkFlowUsers(1, 10),
                    apiCalls.getWorkFlowRequestTypes(),
                    apiCalls.getWorkFlowDurationTypes(),
                    type.label === "Request more information" ? Promise.resolve([]) : apiCalls.getWorkFlowCompletionPolicy()
                ]);
    
                const mapToDropdownData = (items, valueKey, labelKey) => {
                    return items?.map(item => ({
                        value: item[valueKey],
                        label: item[labelKey]
                    }));
                };
     
                setPositions(mapToDropdownData(positions, 'position_id', 'name'));
                setTeams(mapToDropdownData(teams, 'teamheader_id', 'name'));
                setUsers(mapToDropdownData(users, 'user_id', 'employee_display_name'));
                setRequests(mapToDropdownData(requestTypes, 'id', 'name'));
                setDuration(mapToDropdownData(durationType, 'id', 'name'));
                setPolicy(mapToDropdownData(completionPolicy, 'id', 'name'));
            }
        } catch (error) {
            console.error('Error fetching dropdown data:', error);  
        }
    };
    
    // - Select 2 Settings for POST API calls

    let select2SettingsUser = {
        ajax: {
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: (args) => {
                let pageNumber = args.page ? args.page : 1;
                totalDropdownPageUser = pageNumber;
                let pageSize = Settings.dropdownPageSize;
                return `${Settings.apiUrl}/workflow/get_users/${pageNumber}/${pageSize}`;
            },
            data: (dataParams) => JSON.stringify({
                name: dataParams.term ? dataParams.term : null
            }),
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${Api?.getUserToken()}`);
            },
            processResults: (data) => {
                let isMorePageExist = (data?.total_records - (totalDropdownPageUser * Settings.dropdownPageSize)) > 0;
                let result = data?.data?.map(item => ({
                    id: item.user_id,
                    text: item.employee_display_name
                }));
                return {
                    results: result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    };

    let select2SettingsTeam = {
        ajax: {
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            url: (args) => {
                let pageNumber = args.page ? args.page : 1;
                totalDropdownPageTeam = pageNumber;
                let pageSize = Settings.dropdownPageSize;
                return `${Settings.apiUrl}/workflow/get_teams/${pageNumber}/${pageSize}`;
            },
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${Api?.getUserToken()}`);
            },
            data: function () { 
                return {};
            },
            processResults: (data) => {
                let isMorePageExist = (data?.total_records - (totalDropdownPageTeam * Settings.dropdownPageSize)) > 0;
                let result = data?.data?.map(item => ({
                    id: item.teamheader_id,
                    text: item.name
                }));
                return {
                    results: result,
                    pagination: {
                        more: isMorePageExist
                    }
                };
            }
        }
    };

    // -- Dropdown / Click Handlers

    const handleTransitionEnd = () => {
        if (isOpen) { 
            setIsTransitioning(false);
        }
    };

    const OpenDropdown = (index) => { setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index)); };

    const OpenKPIViolate = (index) => { setOpenKPIViolateIndex((prevIndex) => (prevIndex === index ? null : index)); }

    const handleCloseClick = () => {
        if(isApiCallMade) return;

        $('html').removeClass('rs_popup_open'); // triggering the class to add the scrollbar,

        setIsTransitioning(true);
        closeSidebar(false);
    };
  
    const DescriptionHandler = (event) => {
        let description = event.target.value
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                description: description,
            }
        })
    } 

    const InputOnChangeHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                duration: evt.target.value
            }
        })
    }

    const RequestTypeHandler = (evt) => {
        const ReqType = request?.find(id => id.value == evt.target.value)?.label;

        switch (ReqType) {
            case "User":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Hierarchy":
                // Handle API call for hierarchy, workflow Team Req
                setShowUsers(false);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(true);
                break;
            case "Team":
                setShowTeams(true);
                setShowPositions(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "Position":
                setShowPositions(true);
                setShowTeams(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "Dynamic Team":
                setShowTeams(true);
                setShowPositions(false);
                setShowUsers(false);
                setShowPolicy(true);
                break;
            case "SME":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Requester":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            case "Requester Supervisor's":
                setShowUsers(true);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
            default:
                setShowUsers(false);
                setShowTeams(false);
                setShowPositions(false);
                setShowPolicy(false);
                break;
        }
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                new_assignee_type: evt.target.value
            }
        })
    }

    const DurationTypeHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                duration_type: evt.target.value
            }
        })
    }
    
    const CompletionPolicyHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                completion_policy: evt.target.value
            }
        })
        if(evt.target.value == 3) setShowPercentageApprover(true); 
        else setShowPercentageApprover(false);
    }

    const PercentageApproverHandler = (evt) => {
        let value = evt.target.value
        if(value >= 1 && value <= 100) { 
            setWorkflowActionDetails((prevState) => {
                return {
                    ...prevState,
                    percentage_of_approver: value
                }
            })
        }else {
            Helper.alert('Invalid Percentage!', 'error')
        }
    }

    const UsersHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                user_id: evt.target.value
            }
        })
    }
    
    const TeamsHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                teams: evt.target.value
            }
        })
    }

    const PositionsHandler = (evt) => {
        setWorkflowActionDetails((prevState) => {
            return {
                ...prevState,
                positions: evt.target.value
            }
        })
    }
     
    // ---

    // Modules based on task action types, - 
    
    const ReCallModule = () => {  
        return (
            <div className='recall-module'>
                <WorkFlowDetails 
                    mainHeading={`${sources && sources.find(val => val.value === source_id)?.label} workflow-recall`}  
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? DatepickerFunctions.convertDateFromDataBase(workFlowActionObject.initiated_date) : '-'} 
                    from={workFlowActionObject && workFlowActionObject.task_assignee_name ? workFlowActionObject.task_assignee_name : '-'}
                /> 
                <div className='input_details'> 
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }
  
    const TaskReAssignModule = () => {
        return (
            <div className='task-reassign-module'>
                <WorkFlowDetails 
                    mainHeading={'Re-Assigned To'}   
                    stage={workFlowActionObject && workFlowActionObject.stage ? workFlowActionObject.stage : '-'} 
                    name={workFlowActionObject && workFlowActionObject.workflow_name ? workFlowActionObject.workflow_name : '-'} 
                    date={workFlowActionObject && workFlowActionObject.initiated_date ? DatepickerFunctions.convertDateFromDataBase(workFlowActionObject.initiated_date) : '-'} 
                    from={workFlowActionObject && workFlowActionObject.task_assignee_name ? workFlowActionObject.task_assignee_name : '-'}
                /> 
                <div className='input_details task_reassign_module_wrapper'> 
                    <PaginatedDropdown required={true} onChange={RequestTypeHandler} value={workflowActionDetails.new_assignee_type} name='new_assignee_type' options={request} placeHolder='Select re-assignment type' label={'Re-assignment type'} id='task_re_assign_id' />
                    {showUsers &&
                        <PaginatedDropdown
                            label={'User'}
                            placeHolder='Select user'
                            select2Settings={select2SettingsUser} 
                            id='workflow_user_id'
                            reRenderRequired={true}
                            options={users}
                            name='workflow_user_name'
                            value={workflowActionDetails.user_id}
                            onChange={UsersHandler}
                        />
                    }
                    {showTeams && 
                        <PaginatedDropdown
                            label={'Team'}
                            placeHolder='Select team'
                            select2Settings={select2SettingsTeam} 
                            id='teams_id'
                            reRenderRequired={true}
                            options={teams}
                            name='teams'
                            value={workflowActionDetails.teams}
                            onChange={TeamsHandler}
                        />
                    }
                    {showPositions && 
                        <PaginatedDropdown
                            label={'Position'} 
                            id='position_id'
                            placeHolder='Select position'
                            reRenderRequired={true}
                            options={positions}
                            name='positions'
                            value={workflowActionDetails.positions}
                            onChange={PositionsHandler}
                        />
                    }
                    {/* {showPolicy && 
                        <Fragment>
                            <h2>Completion policy</h2>
                            <div className='row'>
                                <div className='col-12'>
                                    <PaginatedDropdown onChange={CompletionPolicyHandler} value={workflowActionDetails.completion_policy} name='completion_policy' options={policy} label={'Completion policy'} placeHolder='Select completion policy' id='completion_policy_id' />
                                    {showPercentageApprover && <Input onChange={PercentageApproverHandler} value={workflowActionDetails.percentage_of_approver} name='percentage_of_approver' label={'Percentage Of Approver %'} id='percentage_of_approver_id' />}
                                </div>
                            </div>
                        </Fragment>
                    } */}
                    {/* <h3 className='time-limit-heading'>Time Limit</h3>
                    <div className='row'>
                        <div className='col-6'>
                            <PaginatedDropdown onChange={DurationTypeHandler} name='duration_type' value={workflowActionDetails.duration_type} options={duration} placeHolder='Select type' label={'Type'} id='type_id' />
                        </div>
                        <div className='col-6'>
                            <Input onChange={InputOnChangeHandler} value={workflowActionDetails.duration} label={'Response within'} id='response_within' />
                        </div> 
                    </div> */}
                    <label className='mb-2'>Description</label>  
                    <textarea className='description_lbl' maxLength={255} onChange={DescriptionHandler} value={workflowActionDetails.description} />
                </div>
            </div>
        )
    }

    // ----

    const executeInstanceTaskActionApi = async () => {  
        const conditions = [showUsers && workflowActionDetails.user_id, showTeams && workflowActionDetails.teams, showPositions && workflowActionDetails.positions];
        const resultArray = conditions.filter(Boolean).map(value => parseInt(value));
    
        try {
            const response = await axios.post(
                `${Settings.apiWorkflowUrl}/instance-task/task-action`,
                {
                    "user_id": auth?.user?.user_id,
                    "instance_task_id": workFlowActionObject.instance_task_id,
                    "task_action": parseInt(type.value),
                    "new_assignee_type": workflowActionDetails.new_assignee_type ? parseInt(workflowActionDetails.new_assignee_type) : null,
                    "new_assignee": resultArray,
                    "completion_policy": !showUsers ? (workflowActionDetails.completion_policy ? parseInt(workflowActionDetails.completion_policy) : null) : null,
                    "percentage_of_approver": !showUsers ? (showPercentageApprover ? workflowActionDetails.percentage_of_approver : null) : null,
                    // "duration_type": workflowActionDetails.duration_type ? parseInt(workflowActionDetails.duration_type) : null,
                    // "duration": workflowActionDetails.duration,
                    "comment": workflowActionDetails.description
                },
                { cancelToken: cancelTokenSource?.token }
            );
    
            if (response?.status === 201) { 
                if (setEnableFields && typeof setEnableFields === 'function') setEnableFields(false);
                if (reloadFunction) reloadFunction();  
                handleCloseClick();
                Helper.alert(response?.data?.message);
            }
        } catch (error) { 
            getPopupMessageBasedOnStatus(error);
        }
    } 
    
    const taskActionWorkflowActionSubmitApi = () => { 
        Helper.createDebouncedAPIFunction(
            [executeInstanceTaskActionApi], 
            setIsApiCallMade,
            cancelTokenSource
        )();  
    }

    // Execute API call for Submit Button Handler -
    const SubmitAPICall = () => {
        { 
            taskActionWorkflowActionSubmitApi();
        }
    } 
    return (  
        <div className='workflow_offcanvas_header'>  
            <div className={`offcanvas-overlay ${isOpen ? 'show' : ''}`} onClick={handleCloseClick}></div>

            <div className={`offcanvas-container ${isOpen ? 'show' : ''} ${isTransitioning ? 'transitioning' : ''}`} onTransitionEnd={handleTransitionEnd}>
                <div className="offcanvas-content">
                    {type.label == "Recall" && ReCallModule()} 
                    {type.label == "Task Re-assign" && TaskReAssignModule()} 
 

                    { isApiCallMade ? <NewOverlayLoader isPopupInTab={true} isLogoCenter={true} /> : null }

                    <div className='action_btns'>
                        {
                            (type.label !== "Action History") && 
                            (
                                <Button
                                    isDisable={isApiCallMade}
                                    onClick={handleCloseClick}
                                    title={'Close'}
                                    className="black-btn-style"
                                />
                            )
                        }
                        {(type.label !== 'View current assignment' && type.label !== "Action History") ? (
                            <Button
                                isDisable={isApiCallMade}
                                onClick={SubmitAPICall}
                                title={'Submit'}
                                className="blue-btn-style ms-2"
                            />
                        ) : null } 
                    </div>
                </div>
            </div> 
        </div>   
    )
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth, 
        language:state.language
    }
}

export default  connect (mapStateToProps) (WorkflowOffCanvas); 