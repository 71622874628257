import React, {  useEffect, useRef, useState } from 'react';
import './WorkflowHeader.css';
import MasterComponentWraper from '../../../components/Backend/MasterComponentWraper';
import NavigationHeder from '../../../components/Navigations/NavigationHeder'; 
import DropDownInput from '../../../components/Forms/Dropdown';
import Button from '../../../components/inc/Button';
import PaginatedDropdown from '../../../components/Forms/PaginatedDropdown';
import Helper from '../../../inc/Helper';
import CustomHtmlCell from '../../../components/grid/ag/cellRender/CustomHtmlCell';
import PendingTaskCommonApiCalls from './PendingTaskCommonApiCalls';
import AgGridMemo from '../../../components/grid/ag/AgGridMemo';
import SimpleLoading from '../../../components/Loading/SimpleLoading';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Pagination from '../Conditional Decision Modal/Modal Tab Menu/Assignment/User/Pagination';
import WorkflowOffCanvas from './WorkflowOffCanvas';

let currentSearchTermUser = '';
let sourcesAPICalled = false; 
const WorkflowPendingTasks = () => { 
  const [sources, setSources] = useState([]);
  const [pendingTasks, setPendingTasks] = useState([]); 
  const [workflowFetchTasksDetails, setWorkflowFetchTasksDetails] = useState({});
  const [showCanvas, setShowCanvas] = useState(false);
  const [workflowTasks, setWorkflowTasks] = useState({
    task_type_id: null,
    task_type_name: null,
    module_id: null, 
    workflow_name: null,
    stage: null,
    task_assignee_name: null,
    initiated_date: null
  })

  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const [loading, setLoading] = useState(false);

  const usersDataRef = useRef([]);
  
  useEffect(() => {
    getSystemSources();
  }, []);

  const getSystemSources = () => {
    if(sourcesAPICalled) return;
    sourcesAPICalled = true;

    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/source',{}).then(function(res){
      if(res.data.status==='success'){
        setSources(res?.data?.data?.map((item) => {
          return {
            value: item.source_id,
            label: item.source_name
          }
        })) 
      } 
    })
  };

  const getWorkflowPendingTasksList = async (page_number=1) => {
    try { 
      setLoading(true);

      const apiCalls = new PendingTaskCommonApiCalls();
      const res = await apiCalls.getWorkflowPendingTasks(workflowFetchTasksDetails?.user_id, workflowFetchTasksDetails?.task_type, page_number); 
      
      if (res) {  
        setPendingTasks(res?.data);
        setTotalRecord(res?.total_records); 
      }
    } catch (error) { 
      console.error(error); 
    } finally {
      setLoading(false); 
    }
  };

  const displayRecallBtn = () => { 
    return `<div style="background-color: #12BDB21C" class="rs_active_label_actions">Recall Action</div>`;
  };

  const displayReassignBtn = () => { 
    return `<div style="background-color: #54A0CA1C" class="rs_active_label_actions">Re-assign Action</div>`;
  };

  const onCellClickHandler = (event) => {
    const { module_id, workflow_name, stage, task_assignee_name, initiated_date, instance_task_id } = event?.data;
 
    if (event.colDef.field === 'recall') {
      setShowCanvas(true);
      setWorkflowTasks({
        module_id: module_id, 
        task_type_id: Settings.enum_workflow_data.task_types_recall.id,
        task_type_name: Settings.enum_workflow_data.task_types_recall.name,
        workflow_name: workflow_name,
        stage: stage,
        task_assignee_name: task_assignee_name,
        initiated_date: initiated_date,
        instance_task_id: instance_task_id
      })
    }

    if (event.colDef.field === 'reassign') {
      setShowCanvas(true);
      setWorkflowTasks({
        module_id: module_id, 
        task_type_id: Settings.enum_workflow_data.task_types_reassign.id,
        task_type_name: Settings.enum_workflow_data.task_types_reassign.name,
        workflow_name: workflow_name,
        stage: stage,
        task_assignee_name: task_assignee_name,
        initiated_date: initiated_date,
        instance_task_id: instance_task_id
      })
    }
  };
  
  const mapUsersData = (item) => {
    return {
      text: `${item.first_name} ${item.last_name}`,
      id: item.user_id
    };
  };

  const goPageByNumberWorkflowTasks = (pageNumber) => {
    setPageNumber(pageNumber);
    getWorkflowPendingTasksList(pageNumber);
  };

  const onFetchWorkflowTasks = () => {
    if(!workflowFetchTasksDetails.task_type){
      Helper.alert('Task type field is required','error');
      return;
    };

    getWorkflowPendingTasksList();
    setPageNumber(1);
  };

  const gridColHeaders = [
    { field:'document_number', valueFormatter: (params) => (params?.value ?? '-'), filter: false, sortable: false, minWidth: 280, headerName: "Document number", editable: false },
    { field:'initiated_by_name', filter: false, sortable: false, minWidth: 280, headerName: "Requester", editable: false },
    { field:'initiated_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter: false, sortable: false, minWidth: 280, headerName: "Date", editable: false },
    { field:'assigned_by_name', filter: false, sortable: false, minWidth: 280, headerName: "From", editable: false },
    { field:'due_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter: false, sortable: false, minWidth: 280, headerName: "Due Date", editable: false},
    { field:'module_id', filter: false, sortable: false, minWidth: 280, headerName: "Source", inputType: "dropdown", options: sources, editable: false },
    { field:'recall', filter: false, sortable: false, minWidth: 280, headerName: "Recall", valueFormatter: displayRecallBtn, cellRenderer: CustomHtmlCell, editable: false },
    { field:'reassign', filter: false, sortable: false, minWidth: 280, headerName: "Re-assign", valueFormatter: displayReassignBtn, cellRenderer: CustomHtmlCell, editable: false }
  ];
  return (
    <div className='container-fluid'>
      <NavigationHeder
        hideBackButton={true}
        title={`<span style="color:#000;"> Home > </span>  ${"Workflow Pending Tasks"}`}
      />

      <div className="row">
        <div className='col-lg-3'>  
          <PaginatedDropdown
            options={[]} 
            pagination={true}
            apiUrl={Settings.apiUrl + `/user`}
            additionalParams={{user_type:0}} 
            currentSearchTerm={currentSearchTermUser}
            allDataRef={usersDataRef}
            mapFunction={mapUsersData}
            reRenderRequired={true}
            placeHolder={"Select user"} 
            label={'User'}
            id="user_id"
            onChange={(evt) => {
              setWorkflowFetchTasksDetails((prevState) => {
                return {
                  ...prevState,
                  user_id: evt.target.value
                }
              })
            }}
            value={workflowFetchTasksDetails?.user_id}
          /> 
        </div>

        <div className='col-lg-3'>  
          <DropDownInput
            options={ 
              Settings.enum_workflow_data.instance_state
            }
            required={true}
            reRenderRequired={true}
            placeHolder={"Select task type"} 
            label={'Task type'}
            id="task_type"
            onChange={(evt) => {
              setWorkflowFetchTasksDetails((prevState) => {
                return {
                  ...prevState,
                  task_type: evt.target.value
                }
              })
            }}
            value={workflowFetchTasksDetails?.task_type}
          /> 
        </div>

        <div className='col-lg-6 d-flex justify-content-end align-items-start'>
          <Button isActive={true} title='Fetch Tasks' icon={'/images/icons/refresh-cw.svg'} onClick={onFetchWorkflowTasks} />
        </div>
      </div>

      <div className='pending-tasks-grid-wrapper'>
        { loading ? <SimpleLoading /> : 
          (
            <>
              <AgGridMemo dissableCheckBox={true} hideHeaderRightContents={true} hideFooter={true} id="grid_workflow_pending_tasks" onCellClicked={onCellClickHandler} header={gridColHeaders} data={pendingTasks} allBtnHide={true} viewOnly={true} /> 
              <Pagination hidePaginationBtns={totalRecord==0} totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberWorkflowTasks} totalRecord={totalRecord} />
            </>
          ) 
        }
      </div>

      {showCanvas &&
        <WorkflowOffCanvas
          isOpen={showCanvas}
          sources={sources}
          type={{ label: workflowTasks?.task_type_name, value: workflowTasks?.task_type_id }}
          closeSidebar={setShowCanvas}
          reloadFunction={() => {
            getWorkflowPendingTasksList();
            setPageNumber(1);
          }}
          workFlowActionObject={{
            workflow_name: workflowTasks?.workflow_name,
            stage: workflowTasks?.stage,
            task_assignee_name: workflowTasks?.task_assignee_name,
            initiated_date: workflowTasks?.initiated_date,
            instance_task_id: workflowTasks?.instance_task_id,
            user_id: workflowFetchTasksDetails?.user_id
          }}
          source_id={workflowTasks?.module_id} 
        />
      } 
    </div>
  );
}

export default (MasterComponentWraper(WorkflowPendingTasks));