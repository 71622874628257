import notifyjs from 'notifyjs-browser';
import $ from 'jquery';
import Settings from './Settings';
import Api from './Api';
import store from '../store';
import Cookies from 'js-cookie';
import Sticky from './Sticky';
import CryptoJS from 'crypto-js';
import axios from 'axios';

//require('./Sticky.js');
let Helper ={
    refreshAppScript(){
        //$(window).trigger('resize');
        //window.dispatchEvent(new Event('resize'));
    },
    getNullableValue(value){
        if(!value) {
            return null;
        }
        if(value.length <=0){
            return null;
        }
        if(value.length == ''){
            return null;
        }
        return value;
    },
    makeSticky(selector,options={}){
        options = {
            ...options,
            contained: true,
            class_name:'sticky-active'
        }
        //let sticky = $(selector).sticky({topSpacing:0});
        //let sticky = new $.Zebra_Pin($(selector), options);
        let  sticky  = {
            update:() => {}
        };
        let customSticky = new Sticky(selector,options);
        return customSticky;
    },
    scroll2id(id,offset = 0){
        let scrollToId=  document.getElementById(id);
        Helper.refreshAppScript();
        window.scrollTo(0, ( scrollToId.offsetTop - offset ));
    },
    createDebouncedAPIFunction(funcArray, setDisabledBtnState = () => {}, cancelTokenSource, isClassComp=false, delayTime) { 
    
        /*
          This function takes an array of functions, loops through the functions, 
          gets the repsonse, and it allocates the total time of 10 seconds to the api functions, 
          if responsed in that time, if not then it cancels the api request.
    
          funcArray: ARRAY of Functions with API Calls - Try/Catch Method Mandatory,
          setDisabledBtnState: Set State Function for toggling Save button state for disabling,
          cancelTokenSource: object formed from axios packages, it works for cancelling API requests after API calls exceeds the 10 seconds time.
        */
    
        const debounce = (delay) => {
          let timeoutId;
          let resolvePromise;
    
          return (...args) => {
            clearTimeout(timeoutId);
    
            return new Promise((resolve, reject) => {
              resolvePromise = resolve;
    
              timeoutId = setTimeout(() => {
                cancelAPIRequest();
                reject(new Error('API Call function cancelled due to 10 seconds timed out!'));
              }, delay);
    
              const promises = funcArray.map((func) => func(...args));
    
              Promise.race([Promise.all(promises), timeoutPromise(delay)])
                .then((results) => {
                  clearTimeout(timeoutId);
                  resolvePromise(results);
                })
                .catch((error) => {
                  clearTimeout(timeoutId);
                  reject(error);
                })
                .finally(() => { 
                  if(isClassComp) {
                    setDisabledBtnState({
                      saveBtnApi: false
                    })
                  }else {
                    setDisabledBtnState(false)
                  } 
                });
            });
          };
        };
    
        const timeoutPromise = (delay) => { 
          return new Promise((_, reject) => setTimeout(() => reject(new Error('Function promise timed out!')), delay));
        };
    
        const cancelAPIRequest = () => {
          if (cancelTokenSource && cancelTokenSource.cancel) {
            cancelTokenSource.cancel('Function request cancelled due to time exceeded!');
            resetCancelToken();
          }
        };
      
        const resetCancelToken = () => {
          if (cancelTokenSource) {
            cancelTokenSource = axios.CancelToken.source();
            if (cancelTokenSource.token) {
              cancelTokenSource.token.reason = null;
            }
          }
        };
    
        return async (...args) => {
          try {
            if(isClassComp) {
              setDisabledBtnState({
                saveBtnApi: true
              })
            }else {
              setDisabledBtnState(true)
            } 
            const debouncedFunctions = funcArray.map((func) => debounce(delayTime?delayTime:15000)(func, ...args));
            const results = await Promise.all(debouncedFunctions); 
            return results;
          } catch (error) {
            console.log('Function call error:', error);
          } finally {
            if(isClassComp) {
              setDisabledBtnState({
                saveBtnApi: false
              })
            }else {
              setDisabledBtnState(false)
            } 
          }
        };
    },
    isRtl(){
        var language = document.getElementsByTagName("html")[0].getAttribute("lang")
        return language === 'ar';
    },
    getLabel(LanguageProps,frontend_Id,defaultValue = null,postFix = null){
        if(!LanguageProps){
            return defaultValue;
        }
        let output = defaultValue;
        if(LanguageProps && LanguageProps.allLabels &&  Array.isArray(LanguageProps.allLabels)){
            LanguageProps.allLabels.forEach(label => {
                if(label.frontend_Id === frontend_Id){
                    if(label.TranslatedText){
                        output = label.TranslatedText;
                    }else{
                        output = label.originalText;
                    }
                }
            });
        }
        return output;
    },
    setCookie(cname, cvalue, exdays) {
        Cookies.set(cname, cvalue,{ expires: exdays, path: '/' })
        /*
        var d = new Date();
        let expireTime = (exdays*24*60*60);
        d.setTime(expireTime);
        var expires = "; expires="+ d.toUTCString();
        var maxAge = "; max-age="+expireTime;
        document.cookie = cname + "=" + cvalue + expires + maxAge+"; path=/; SameSite=None";
        */

        //document.cookie = cname + "=" + cvalue + expires + maxAge+"; path=/; SameSite=None; Secure";
    },
    getCookie(cname) {
        return Cookies.get(cname);
        /*
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split('; ');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
        */
    },
    removeCookie(cname) {
        Cookies.remove(cname, { path: '/' })
        //document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //document.cookie = cname+ "=; maxAge=0; expires=" + new Date(0).toUTCString();
    },
    /**
     * 
     * @param {string} message 
     * @param {string} 'success, info, warn, error'
     */
    alert(message,messageType = 'success'){
        if(!Helper.getNullableValue(message)){
            return;
        }
        $.notify(message,{
            className: messageType,
            globalPosition: 'top center',
        });
    },
    getDateForDb(dateString){
        let dateSeperator = dateString.includes('-') ? '-' : '/';
        var dateStArray =dateString.split(dateSeperator);
        let userFormateOfDate = store.getState().dateFormate.formate;
        let dateObject = new Date(); 
        if(userFormateOfDate == 'dd-mm-yyyy' || userFormateOfDate ==  'dd/mm/yyyy'){
            dateObject = new Date(dateStArray[2]+'-'+dateStArray[1]+'-'+dateStArray[0]); 
        }else if(userFormateOfDate == 'yyyy-mm-dd' || userFormateOfDate == 'yyyy/mm/dd'){
            dateObject = new Date(dateStArray[0]+'-'+dateStArray[1]+'-'+dateStArray[1]); 
        }else if(userFormateOfDate == 'mm-dd-yyyy' || userFormateOfDate == 'mm/dd/yyyy'){
            dateObject = new Date(dateStArray[2]+'-'+dateStArray[0]+'-'+dateStArray[1]); 
        }
        return `${dateObject.getFullYear() }-${ ( dateObject.getMonth() + 1 ) }-${dateObject.getDate()}`;
    },
    getDatePickerFormate(){
        let userFormateOfDate = store.getState().dateFormate.formate;
        let dateSeperator = userFormateOfDate.includes('-') ? '-' : '/';
        if(userFormateOfDate == 'dd-mm-yyyy' || userFormateOfDate ==  'dd/mm/yyyy'){
            return `d${dateSeperator}m${dateSeperator}Y`;
        }else if(userFormateOfDate == 'yyyy-mm-dd' || userFormateOfDate == 'yyyy/mm/dd'){
            return `Y${dateSeperator}m${dateSeperator}d`;
        }else if(userFormateOfDate == 'mm-dd-yyyy' || userFormateOfDate == 'mm/dd/yyyy'){
            return `m${dateSeperator}d${dateSeperator}Y`;
        }
        return `d${dateSeperator}m${dateSeperator}Y`;
    },
    convertDateFromDb(DbDateString){
        let dateObj = new Date(DbDateString);
        return Helper.formateDate(dateObj.getFullYear()+'-'+( dateObj.getMonth() + 1)+'-'+dateObj.getDate());
    },
    isFrontendFormatedDate(string){
        let userFormateOfDate = store.getState().dateFormate.formate;
        let datePattern = userFormateOfDate.includes('-') ? /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/ : /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
        return datePattern.test(string);
    },
    /**
     * 
     * @param {String} dateString 
     * @param {String} formate 
     * @returns {String}
     */
    formateDate ( dateString, options= {}){
        if(dateString == "" || dateString == null){
            return dateString;
        }
        let dateOptions = {
            seperator:'/',
            ...options
        }
        const dateObject = new Date(dateString);
        if(!dateObject.getDate()){
            return dateString;
        }
        let userFormateOfDate = store.getState().dateFormate.formate;
        let dateSeperator = userFormateOfDate.includes('-') ? '-' : dateOptions.seperator;
        let day =  `${dateObject.getDate()}`.padStart(2,"0");
        let month = `${dateObject.getMonth() +1}`.padStart(2,"0");
        let year =  `${dateObject.getFullYear()}`.padStart(4,"0");
        let finalDate = `${day + dateSeperator + month + dateSeperator + year}`;
        if(userFormateOfDate == 'dd-mm-yyyy' || userFormateOfDate ==  'dd/mm/yyyy'){
            finalDate = `${day + dateSeperator + month + dateSeperator + year}`;
        }else if(userFormateOfDate == 'yyyy-mm-dd' || userFormateOfDate == 'yyyy/mm/dd'){
            finalDate = `${year + dateSeperator + month + dateSeperator + day}`;
        }else if(userFormateOfDate == 'mm-dd-yyyy' || userFormateOfDate == 'mm/dd/yyyy'){
            finalDate = `${month + dateSeperator + day + dateSeperator +  year}`;
        }
        return finalDate;
    },
    /**
     * 
     * @param {Object} dataObjcet 
     */
    gridDateView(dataObjcet){
        let output = dataObjcet.value;
        return Helper.formateDate(output);
    },
    getCountryFromName(allCountry, CountryName ){
        let country = {};
        allCountry.forEach(item => {
            if(item.country_short_name ===  CountryName){
                country = item;
            }
        })
        return country;
    },
    getStateFromName(allState, StateName ){
        let state = {};
        allState.forEach(item => {
            if(item.name ===  StateName){
                state = item;
            }
        })
        return state;
    },
    getCityFromName(allCity, CityName ){
        let city = {
            city_id:''
        };
        allCity.forEach(item => {
            if(item.city_name ===  CityName){
                city = item;
            }
        })
        return city;
    },
    getRandom(max= 1000){
        return Math.floor(Math.random() * max) + 1;
    },
    /* this format for address grid */
    getContactsFromGrid(gridObj){
        let gridData = Helper.getDataFromGrid(gridObj)
        let contactsList = gridData.map( contactItem => {
            contactItem.isprimary = contactItem.is_primary == 'yes' ? true : false;
            contactItem.isactive = contactItem.is_active = contactItem.is_active == 'yes' ? true : false;
            contactItem.mobile = contactItem.mobile == 'yes' ? true : false;
            contactItem.is_primary = contactItem.isprimary;
            contactItem.is_active = contactItem.isactive;
            return contactItem;
        })
        return contactsList;
    },
    /* this format for contact grid */
    getAddressFromGrid(gridObj){
        let gridData = Helper.getDataFromGrid(gridObj);
        let addressData = gridData.map( addressItem =>{
            addressItem.isprimary  = addressItem.is_primary == "yes" ? true: false;
            addressItem.isprivate  = addressItem.is_private == "yes" ? true: false;
            addressItem.is_primary = addressItem.isprimary;
            addressItem.is_private = addressItem.isprivate;
            addressItem.contacts = addressItem.contacts.map(contactItem => {
                contactItem.isprimary = contactItem.is_primary = contactItem.is_primary == 'yes' ? true : false;
                contactItem.isactive = contactItem.is_active = contactItem.is_active == 'yes' ? true : false;
                contactItem.mobile = contactItem.mobile === 'yes' ? true : false;
                return contactItem;
            })
            addressItem.contact = {
                contacts:addressItem.contacts
            }
            return addressItem;
        })
        return addressData;
    },
    getDataFromGrid(gridObj){
        let gridData = [];
        gridObj.api.forEachNode( gridItem => {
            gridData.push(gridItem.data);
        })
        return gridData;
    },
    createNoteIfExist(note,sourceId, integrator, afterCreate){
        if(note.length <=0){
            afterCreate({
                type:'success'
            })
            return;
        }
        let noteData = {}

        noteData.source_id =sourceId;
        noteData.notes = [note];
        noteData.notesource_integrator = integrator;
        let api = Api;
        api.setUserToken();

        api.axios().post( Settings.apiUrl + '/notesource',noteData).then(function(res){
            afterCreate({
                type:'success',
                data: res.data
            })
        }).catch(error => {
            afterCreate({
                type:'fail',
                data: error
            })
        })
    },
    encryptPassword(password){
        const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;    
        const derived_key = CryptoJS.enc.Base64.parse(secretKey);    
        const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);  
        const encryptionOptions = {
            iv: iv,
            mode: CryptoJS.mode.CBC
        };  
        const encryptedPassword = CryptoJS.AES.encrypt(password, derived_key, encryptionOptions).toString();    
        return encryptedPassword;
      }
}
export default Helper;
